import * as React from "react"
import Layout from "../components/layout"
import Commission from "../components/Commission"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const IndexPage = () => {
  return (
    <Layout>
    <main>
      <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <h1 className={'text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mt-24 mb-8 max-w-screen-lg'}>In my work I hope to show, not only a physical representation but to achieve a deeper sense of the sitter.</h1>
        <h2 className={'text-2xl font-bold'}>Gillian Morrow</h2>
        <p className={'text-lg'}>artist & sculptor</p>
      </div>
      <div className={'mx-auto max-w-screen-xl w-full lg:px-6 py-8 lg:py-16 relative'}>
        <StaticImage
          src="../images/home.jpg"
          alt="Gillian Morrow"
          layout="fullWidth"
        />
      </div>
      <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <h2 className={'text-2xl sm:text-3xl lg:text-4xl font-bold my-4 max-w-screen-md'}>My name is Gill, artist and sculptor. I am a Belfast based figurative artist currently focusing on busts and portraits.</h2>
        <Link to="/about/" className={'border-b-2 border-black hover:border-transparent transition py-1 mr-8'}>about me</Link>
        <Link to="/portfolio/" className={'border-b-2 border-black hover:border-transparent transition py-1'}>see my portfolio</Link>
      </div>
      <div className={'mx-auto max-w-screen-xl w-full lg:px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-3 lg:gap-6'}>
          <StaticImage
            src="../images/gallery/1.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/2.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/3.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/4.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/5.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/7.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
        </div>
      </div>

      <Commission />
    </main>

    </Layout>
  )
}
export default IndexPage

export const Head = () => (
    <>
    <title>Gill Morrow Art</title>
    <meta name="description" content="ceramic sculpted portrait busts" />
    </>
)
