import React from "react";

export default function Commission() {
  return (
    <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
      <h2 className={'text-2xl sm:text-3xl lg:text-4xl font-bold my-4 max-w-screen-lg'}>Commission something beautiful.</h2>
      <p className={'text-xl md:text-2xl mb-16 max-w-screen-md'}>Contact me today to arrange a consultation.</p>
      <strong className={'text-xl mb-2'}>Email address</strong><br />
      <a href="mailto:gill.morrow@hotmail.co.uk" className={'border-b-2 border-black hover:border-transparent transition py-1'}>gill.morrow@hotmail.co.uk</a>
    </div>
  )
}
